import React from 'react';
import './offer.css';

const Offer = () => {
    const services = [
        {id: 1,photo: "./images/web.png",title: "Data Management",description: "Data management refers to the process of collecting, storing, organizing, and analyzing data to ensure its accuracy"},
        {id: 2,photo: "./images/data1.png",title: "Data Science",description: "Data science is the process of using algorithms, methods and systems to extract knowledge and insights."},
        {id: 3,photo: "./images/mobile.png",title: "Cloud Service",description: "Cloud services refer to computing resources and services that are delivered over the internet on-demand,."},
        {id: 4,photo: "./images/datamange.png",title: "Data Analysis",description: "Data analysis is the process of inspecting, cleansing, transforming, and modeling data with the goal of discovering useful information"},
        {id: 5,photo: "./images/opti.png",title: "Optimization",description: "Optimization refers to the process of making something as effective, efficient, or functional as possible. In various contexts, algorithms, resources,"},
        {id: 6,photo: "./images/market.png",title: "Customer Experience",description: "The quality assurance process helps a business ensure its products meet the quality standards set by the company."},
        {id: 7,photo: "./images/nps.png",title: "Requirments",description: "C-J Technology aims to assist businesses in assembling a team of highly efficient and competent individuals."},
        {id: 8,photo: "./images/opti.png",title: "Market Research CS Benchmarking",description: "Early Research Making market research capabilities more valuable by gaining access to new and underutilized."},
        {id: 9,photo: "./images/web.png",title: "Website Design",description: "C-J TECH is an India based website designing and development company."},
        {id: 10,photo: "./images/quality.png",title: "Responsive Web Design",description: "Responsive web design is a device-independent user interface design that aims to develop and deliver an optimized."},
        {id: 11,photo: "./images/req.png",title: "Custom Website Design",description: "Your website is an online identity for your brand name."},
        {id: 12,photo: "./images/web.png",title: "Remotley",description: " Remote resources refer to any tools, assets, or support systems that are accessible and utilized by individuals or teams working remotely.  "},
        {id: 13,photo: "./images/cloud.png",title: "Freelance Support",description: "Freelance Support resources refer to any tools, assets, or support systems that are accessible and utilized by individuals or teams working remotely.."},
        {id: 15,photo: "./images/custom.png",title: "Aws Resourses",description: "Aws resources encompass a variety of tools, practices, and methodologies aimed at improving collaboration, automation,development (Dev) and IT operations (Ops) teams"},
        {id: 16,photo: "./images/custom.png",title: "Devops Resourses",description: "DevOps resources encompass a variety of tools, practices, and methodologies aimed at improving collaboration, automation, and efficiency and IT operations (Ops) teams"},
        {id: 17,photo: "./images/custom.png",title: "AI Resourses",description: "AI (Artificial Intelligence) resources encompass a wide range of tools, frameworks, libraries, datasets, and educational materials aimed at advancing AI research"}

    ];

    return (
        <div className='service-wrap-section'>
            <div className='heading'>SPECIALISED IN</div>
            <div className='sub-heading'>What We Offer</div>
            <div className='services-wrap'>
                {services.map((service) => (
                    <div className='services' key={service.id}>
                        <div className="img-wrap"><img src={service.photo} alt="image" /></div>
                        <div className='bottom-info'>
                            <div className='title'>{service.title}</div>
                            <div className='review'>{service.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Offer;
